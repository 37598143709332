<template>
  <v-expansion-panels :value="myPanel" multiple>
    <v-expansion-panel>
      <v-expansion-panel-header
        >{{ $t("searchPanel") }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="customerName"
              :items="customers"
              clearable
              :label="$t('customers.customerName')"
              item-text="customerDisplayName"
              item-value="guid"
              :return-object="false"
              hide-details
              outlined
              dense
            >
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="productName"
              :items="products"
              clearable
              :label="$t('products.product')"
              item-text="productDisplayName"
              item-value="guid"
              :return-object="false"
              hide-details
              outlined
              dense
            >
            </v-autocomplete>
          </v-col>
        
        </v-row>
        <v-row v-if="isReport">
          <v-col >
            <v-dialog
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
    
                <v-text-field
                  v-model="fromDate" :disabled="allDates"
                  hide-details
                  outlined
                  :rules="[required]"
                  dense
                  :label="$t('fromDate')"
                  persistent-hint
                  v-bind="attrs"
                  v-on="on"
                >
                <template v-slot:prepend>
                  <v-checkbox v-model="allDates"
                   hide-details class="ma-0 pa-0"
                   :title="$t('allDates')"
                   >

                  </v-checkbox>

                </template>
              
              </v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                no-title
                @input="menu1 = false"
              ></v-date-picker>
            </v-dialog>
          </v-col>
          <v-col>
            
            <v-dialog
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field :disabled="allDates"
                  hide-details
                  outlined
                  dense
                  :rules="[required]"
                  v-model="toDate"
                  :label="$t('toDate')"
                  persistent-hint
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-dialog>
          </v-col>
          <v-col>
            <v-text-field
                  hide-details 
                  outlined
                  dense
                  v-model="serialNumber"
                  :label="$t('serialNumber')"
                  persistent-hint
                ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                  hide-details
                  outlined
                  dense
                  v-model="machineCode"
                  :label="$t('machineCode')"
                  persistent-hint
                ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">
            <v-btn :disabled="isReport && (!fromDate || !toDate)" color="primary" @click="filterChange()">
              <v-icon> mdi-refresh </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: ["panel",'isReport'],
  data() {
    return {
      //panel: [0],
      menu1: null,
      menu2: null,
      required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      fromDate:this.$moment(new Date()).format('YYYY-MM-DD') ,
      toDate: this.$moment(new Date()).format('YYYY-MM-DD'),
      machineCode:"",
      serialNumber:"",
      allDates:false,
      customerName: "",
      productName: "",
      customers: [],
      products: [],
    };
  },
  created() {
    //this.fromDate = this.dateNow;
    //this.toDate = this.dateNow;
    //if (this.panel) this.myPanel.push(this.panel);

    this.$axios
      .get("Customers/Get")
      .then((response) => {
        this.customers = response.data.data;
      })
      .catch((e) => {
        //this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
        console.log(e);
      });

    this.$axios
      .get("Products/Get")
      .then((response) => {
        this.products = response.data.data;
        this.products. forEach(element => {
        element.productDisplayName= element.productDisplayName +' - '+element.productName
      });
      })
      .catch((e) => {
        //this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
        console.log(e);
      });
  },
  watch: {
    customerName() {
      if(!this.isReport)
      this.filterChange();
    },
    productName() {
      if(!this.isReport)
      this.filterChange();
    },
  },
  computed: {
    myPanel() {
      return this.panel ? [0] : [];
    },
  },
  methods: {
    // patientNameKeyPress(e) {
    //   if (e.key == "Enter") {
    //     if (this.patientName) {
    //       this.filterChange();
    //     }
    //   }
    // },
    filterChange() {
      var filterItem = {
        productName: this.productName,
        customerName: this.customerName,
        fromDate: this.fromDate,
        toDate: this.toDate,
        machineCode: this.machineCode,
        serialNumber: this.serialNumber,
        allDates: this.allDates,
      };
      this.$emit("filterChange", filterItem);
    },
  },
};
</script>

<style scoped>
.v-input--is-disabled:not(.v-input--is-readonly) {
    pointer-events: unset !important;
}
</style>
